exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-gifts-tsx": () => import("./../../../src/pages/gifts.tsx" /* webpackChunkName: "component---src-pages-gifts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-product-details-tsx": () => import("./../../../src/templates/productDetails.tsx" /* webpackChunkName: "component---src-templates-product-details-tsx" */)
}

